import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  private isBrowser = true;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  public scrollTop() {
    if (this.isBrowser) {
      window.scrollTo(0, 0);
    }
  }

  public scrollIntoViewByObjectId(objectId: string, behavior) {
    if (this.isBrowser) {
      document.getElementById(objectId).scrollIntoView({behavior: behavior});
    }
  }

  public dataLayerPush(event) {
    if (this.isBrowser) {
      window.dataLayer.push(event);
    }
  }
}
