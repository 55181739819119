import {Resolve, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AmplifyService} from 'aws-amplify-angular';
import {CustomAdminUserService} from '../services/custom-admin-user.service';
import {IUserAuthResolveInterface} from '../interfaces/user-auth-resolve.interface';
import {MyProfileService} from '../services/my-profile.service';
import {BrandService} from '../services/brand.service';
import {Store} from '@ngrx/store';
import {AppState} from '../state/state';
import {setInfluencer} from '../state/actions';
import {UserRoleEnum} from '@enums';

@Injectable()
export class AuthUserResolver implements Resolve<IUserAuthResolveInterface> {

  private customInfluencerId: string;

  private static influencerRoles = [
    UserRoleEnum.Influencer, UserRoleEnum.Professional
  ];

  constructor(
    private router: Router,
    private amplifyService: AmplifyService,
    private customAdminUserService: CustomAdminUserService,
    private myProfileService: MyProfileService,
    private brandService: BrandService,
    private store: Store<{ app: AppState }>
  ) {
  }

  resolve(): Promise<IUserAuthResolveInterface> {

    this.customInfluencerId = this.customAdminUserService.customInfluencerId;

    return this.amplifyService.auth().currentUserInfo()
      .then(user => {
        if (!user) {
          this.router.navigate(['/sign-in'])
            .catch(err => console.error(err));
        }

        let role = user.attributes && user.attributes['custom:role'];
        role = role ? role : 'influencer';

        const isAdmin = !!(user.attributes && user.attributes['custom:role'] && user.attributes['custom:role'] === 'admin');
        if (this.customInfluencerId) {
          return {influencerId: this.customInfluencerId, isAdmin, role};
        } else {
          if (user && user.attributes && user.attributes.sub) {
            const influencerId = user.attributes.sub;

            if (isAdmin || AuthUserResolver.influencerRoles.indexOf(role) === -1) {
              return {influencerId, isAdmin, role};
            } else {
              return this.myProfileService.getInfluencerProfileData(influencerId)
                .toPromise()
                .then(profile => {
                  if (profile && profile.isAgreedToTerms) {
                    this.store.dispatch(setInfluencer({influencer: profile}));
                    // @ts-ignore
                    if (profile.brand.length === 0) {
                      this.brandService.setCurrentBrand(this.brandService.defaultBrand);
                    } else if (profile.brand.indexOf(this.brandService.getCurrentBrand().value) === -1) {
                      this.brandService.setCurrentBrand(profile.brand[0] ? profile.brand[0] : this.brandService.defaultBrand);
                    }
                    if (!this.brandService.getCurrentBrand().value) {
                      this.brandService.setCurrentBrand(profile.brand[0] ? profile.brand[0] : '');
                    }

                    return {influencerId, isAdmin, role};
                  } else {
                    this.router.navigate([`/terms-conditions/${influencerId}`])
                      .catch(err => console.error(err));
                  }
                });
            }
          }
        }
      });
  }
}
