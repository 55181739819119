import {ErrorHandler, Injectable} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {environment} from '../../environments/environment';

Sentry.init({
  dsn: environment.sentryDsnUrl
});

@Injectable({
  providedIn: 'root'
})
export class SentryErrorHandlerService implements ErrorHandler {
  constructor() {
  }

  handleError(error) {
    if (environment.name === 'production') {
      Sentry.captureException(error.originalError || error);
    }
    throw error;
  }
}
