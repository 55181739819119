import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {forkJoin, Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {LookItemInterface} from '../interfaces/look-item.interface';
import {LooksFavoritesDataInterface} from '../interfaces/looks-favorites-data.interface';
import {LeanLookItemInterface} from '../interfaces/lean-looks-favorites-data.interface';

@Injectable({
  providedIn: 'root'
})
export class CarouselsEditingService {
  private readonly storeBaseApiUrl = `${environment.storeBaseApiUrl}`;
  private readonly influencersBaseApiUrl = `${environment.baseApiUrl}`;

  constructor(private http: HttpClient) {
  }

  public getAllFavoritesLooks(): Observable<LooksFavoritesDataInterface> {
    const apiPath = 'looks/favorites';
    const params = new HttpParams().set('limit', '15').set('offset', '0');
    const top$ = this.http.get<LookItemInterface[]>(`${this.storeBaseApiUrl}${apiPath}`, {params});
    const topEyes$ = this.http.get<LookItemInterface[]>(`${this.storeBaseApiUrl}${apiPath}`, {params: params.set('category', 'Eyes')});
    const topLips$ = this.http.get<LookItemInterface[]>(`${this.storeBaseApiUrl}${apiPath}`, {params: params.set('category', 'Lips')});

    return forkJoin([top$, topEyes$, topLips$])
      .pipe(
        map(([top, topEyes, topLips]) => {
          return {top, topEyes, topLips};
        }),
        catchError(val => throwError(val))
      );
  }

  public getAllLooks(): Observable<LookItemInterface[]> {
    const apiPath = 'looks';

    return this.http.get<LookItemInterface[]>(`${this.influencersBaseApiUrl}${apiPath}`)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public removeLookFromFavorites(lookId: string): Observable<LookItemInterface[]> {
    const apiPath = `looks/favorites/${lookId}`;

    return this.http.delete<LookItemInterface[]>(`${this.influencersBaseApiUrl}${apiPath}`)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public addLookToFavorites(lookId: string, category: string, order: number): Observable<LookItemInterface> {
    const apiPath = `looks/favorites/${lookId}`;

    return this.http.post<LookItemInterface>(`${this.influencersBaseApiUrl}${apiPath}`, {category, order})
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public updateFavoritesData(looksData: LeanLookItemInterface[], category: string): Observable<LookItemInterface[]> {
    const apiPath = `looks/favorites/order/update`;

    return this.http.post<LookItemInterface[]>(`${this.influencersBaseApiUrl}${apiPath}`, {looksData, category})
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }
}
