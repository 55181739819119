// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import {environment} from './environments/environment';

let awsmobile;

const developmentPool = {
  'aws_project_region': 'us-east-1',
  'aws_cognito_identity_pool_id': 'us-east-1:9957a253-2e31-4b2d-859c-f8727b78069b',
  'aws_cognito_region': 'us-east-1',
  'aws_user_pools_id': 'us-east-1_zuyRk9aOE',
  'aws_user_pools_web_client_id': '555i5fs1l3tlosgtko3nn5jupe'
};

const stagePool = {
  'aws_project_region': 'us-east-1',
  'aws_cognito_identity_pool_id': 'us-east-1:c03195ad-6ab3-4318-82a7-16eec956567c',
  'aws_cognito_region': 'us-east-1',
  'aws_user_pools_id': 'us-east-1_7OVhJKNh8',
  'aws_user_pools_web_client_id': '1ec01g4e2a0n9hbv5kaq7ut1m'
};

const productionPool = {
  'aws_project_region': 'us-east-1',
  'aws_cognito_identity_pool_id': 'us-east-1:91439dcd-3deb-4ce3-83d7-c92c318b8d21',
  'aws_cognito_region': 'us-east-1',
  'aws_user_pools_id': 'us-east-1_GftDAxJ99',
  'aws_user_pools_web_client_id': '175bt0gkin73pnkpbpvr53ffth'
};

if (environment.name === 'production') {
  awsmobile = productionPool;
} else if (environment.name === 'stage') {
  awsmobile = stagePool;
} else {
  awsmobile = developmentPool;
}

export default awsmobile;
