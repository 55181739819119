import {InfluencerProfileInterface} from '../interfaces/influencer-profile.interface';

// tslint:disable-next-line:no-empty-interface
export interface InfluencerState extends InfluencerProfileInterface {
}

export interface AppState {
  isAddItemShown: boolean;
  isPreloaderShown: boolean;
  influencer: InfluencerState;
}

export const INITIAL_APP_STATE: AppState = {
  isAddItemShown: true,
  isPreloaderShown: false,
  influencer: null
};
