import {Resolve, Router} from '@angular/router';
import {Injectable, Inject} from '@angular/core';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ResetPasswordResolver implements Resolve<boolean> {

    constructor(
        private router: Router,
        @Inject(LOCAL_STORAGE) private storage: StorageService
    ) {
    }

    resolve(): Promise<any> {
        return this.checkEmail()
        .then(result => {
            if (!result) {
                this.router.navigate(['/sign-in'])
                    .catch((err) => console.log(err));
            }
            return result;
        });
    }

    private checkEmail(): Promise<boolean> {
        return Promise.resolve(!!this.storage.get(environment.signUpStorageKey));
    }
}
