import {Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {LookItemInterface} from '../interfaces/look-item.interface';
import {Observable} from 'rxjs';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router/src/router_state';
import {EditLookService, LOOK_ITEM} from '../services/edit-look.service';
import {tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../state/state';
import {setPreloaderShown} from '../state/actions';

@Injectable({
  providedIn: 'root'
})
export class LookItemResolver implements Resolve<LookItemInterface> {

  constructor(
    private editLookService: EditLookService,
    private store: Store<{ app: AppState }>
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this
      .editLookService
      .getEntryById(route.params['entryId'], route.params['influencerId'], LOOK_ITEM)
      .pipe(
        tap(() => this.store.dispatch(setPreloaderShown()))
      );
  }
}
