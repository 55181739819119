export abstract class AppConstants {
  static readonly filestackTransformations: string = 'cache=expiry:max/resize=width:1000,height:1000/quality=value:80/output=format:jpg';
  static readonly filestackOptions: object = {
    maxFiles: 1,
    accept: 'image/*',
    transformations: {
      crop: {
        aspectRatio: 1,
        force: true
      },
      circle: false,
      rotate: true
    },
    exposeOriginalFile: false,
    allowManualRetry: true,
    fromSources: ['local_file_system', 'url', 'imagesearch', 'facebook', 'instagram', 'googledrive', 'webcam', 'box', 'github', 'gmail', 'picasa', 'onedrive'],
    storeTo: {
      access: 'public',
      location: 's3'
    }
  };
  static readonly baseUrlPrefixForS3: string = 'https://s3.amazonaws.com';
  static readonly baseUrlPrefixForFilestack: string = 'https://cdn.filestackcontent.com';
}
