import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BrandEnum} from '@enums';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  public readonly defaultBrand = BrandEnum['Il Makiage'];
  private readonly brandKey = 'currentBrand';
  private currentBrand: BehaviorSubject<string>;

  constructor() {
  }

  public setCurrentBrand(data: string): BehaviorSubject<string> {
    const value = data === null ? '' : data;
    try {
      localStorage.setItem(this.brandKey, JSON.stringify(value));
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
    if (this.currentBrand) {
      this.currentBrand.next(value);
      return this.currentBrand;
    }
    return this.currentBrand = new BehaviorSubject(value);
  }

  public flushCurrentBrand() {
    this.currentBrand.next(null);
    localStorage.removeItem(this.brandKey);
  }

  public getCurrentBrand(): BehaviorSubject<string> {
    try {
      if (this.currentBrand) {
        return this.currentBrand;
      } else {
        let value = JSON.parse(localStorage.getItem(this.brandKey));
        value = value === null ? '' : value;
        return this.currentBrand = new BehaviorSubject(value);
      }
    } catch (e) {
      console.error('Error getting data from localStorage', e);
    }
  }
}
