import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SignUpParams } from '@aws-amplify/auth/src/types';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import { AmplifyService } from 'aws-amplify-angular';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { InfluencerProfileInterface } from '../interfaces/influencer-profile.interface';
import { UserModel } from '../modules/add-users/components/add-user/add-user.component';

interface IRequestSignUpPayload {
  brands: Array<string>;
  email: string;
  firstName: string;
  instagramUrl: string;
  lastName: string;
  other: string;
  tikTokUrl: string;
  youtubeURL: string;
}

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  private readonly baseApiUrl = `${environment.baseApiUrl}`;

  constructor(private http: HttpClient, @Inject(LOCAL_STORAGE) private storage: StorageService, private amplifyService: AmplifyService) {
  }

  public signoutUser(): Promise<any> {
    return this.amplifyService.auth().signOut();
  }

  public signupUser(payload: SignUpParams): Promise<ISignUpResult> {
    return this.amplifyService.auth().signUp(payload);
  }

  public signInBatch(users: Array<UserModel>): Observable<{ processed: Array<number> } | any> {
    const apiPath = 'invites/influencers';
    return this.http.post<{ valid: boolean }>(`${this.baseApiUrl}${apiPath}`, { ...users })
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public signinUser(username: string, password: string): Promise<any> {
    return this.amplifyService.auth().signIn(username, password);
  }

  public submitSignupCodeVerification(username: string, code: string): Promise<any> {
    return this.amplifyService.auth().confirmSignUp(username, code);
  }

  public resendConfirmationCode(username: string): Promise<string> {
    return this.amplifyService.auth().resendSignUp(username);
  }

  public forgotPassword(username: string): Promise<any> {
    return this.amplifyService.auth().forgotPassword(username);
  }

  public submitForgotPasswordCodeVerification(username: string, code: string, password: string): Promise<void> {
    return this.amplifyService.auth().forgotPasswordSubmit(username, code, password);
  }

  public getCurrentUserInfo(): Promise<any> {
    return this.amplifyService.auth().currentUserInfo();
  }

  public createInfluencer(username: string, id: string): Observable<InfluencerProfileInterface> {
    const apiPath = 'influencer';

    return this.http.post<InfluencerProfileInterface>(`${this.baseApiUrl}${apiPath}`, { username, id })
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public updateInfluencerRegistrationData(influencerId: string, payload: any): Observable<any> {
    const apiPath = `influencer/register/${influencerId}`;
    return this.http.put(`${this.baseApiUrl}${apiPath}`, payload)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public requestRegistration(payload: IRequestSignUpPayload): Observable<{ result: boolean }> {
    const apiPath = `influencer/register/request`;
    return this.http.post<{ result: boolean }>(`${this.baseApiUrl}${apiPath}`, payload)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public verifyToken(token, email): Observable<{ valid: boolean }> {
    const apiPath = 'invites/validateToken';
    return this.http.post<{ valid: boolean }>(`${this.baseApiUrl}${apiPath}`, { token, email })
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

}
