import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {InfluencerProfileInterface} from '../interfaces/influencer-profile.interface';

@Injectable({
  providedIn: 'root'
})
export class MyProfileService {

  private readonly baseApiUrl = `${environment.baseApiUrl}`;

  constructor(private http: HttpClient) {
  }

  public getInfluencerProfileData(influencerId: string): Observable<InfluencerProfileInterface> {
    const apiPath = `influencer/${influencerId}`;

    return this.http.get<InfluencerProfileInterface>(`${this.baseApiUrl}${apiPath}`)
      .pipe(catchError(val => throwError(val)));
  }

  public updateInfluencerProfileData(payload: any, influencerId: string): Observable<any> {
    const apiPath = `influencer/profile/${influencerId}`;

    return this.http.put(`${this.baseApiUrl}${apiPath}`, payload)
      .pipe(catchError(val => throwError(val)));
  }

  public uploadProfileImage(payload: any): Observable<any> {
    const apiPath = 'images/influencer';

    return this.http.post(`${this.baseApiUrl}${apiPath}`, payload)
      .pipe(catchError(val => throwError(val)));
  }

}
