import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomAdminUserService {

  private _customInfluencerId: string;

  get customInfluencerId(): string {
    return this._customInfluencerId;
  }

  set customInfluencerId(influencerId) {
    this._customInfluencerId = influencerId;
  }
}
