import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'lookStatusDisplayText'
})
export class LookStatusDisplayTextPipe implements PipeTransform {

  private statues = [
    'Draft',
    'Published',
    'Pending Approval',
    'Unpublished',
    'Attention',
    'Deleted'
  ];

  transform(statusId: number): string {
    return this.statues[statusId];
  }

}
