import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';

export const REVIEW_ITEM = 'review';
export const LOOK_ITEM = 'look';

@Injectable({
  providedIn: 'root'
})
export class EditLookService {

  private readonly baseApiUrl = `${environment.baseApiUrl}`;

  constructor(private http: HttpClient) {
  }

  public getEntryById(lookId: string, influencerId: string, type): Observable<any> {
    const apiPath = `look/${influencerId}/${lookId}`;

    const options = {
      params: new HttpParams().set('type', type)
    };

    return this.http.get(`${this.baseApiUrl}${apiPath}`, options)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }
}
