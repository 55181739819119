import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AmplifyService} from 'aws-amplify-angular';
import {from, Observable} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {CognitoUserSession} from 'amazon-cognito-identity-js';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private amplifyService: AmplifyService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.indexOf(environment.baseApiUrl) === -1) {
      return next.handle(req);
    } else {
      return from(this.amplifyService.auth().currentSession())
        .pipe(
          switchMap((data: CognitoUserSession) => {
            if (data && data.isValid && data.isValid()) {
              const authReq = req.clone({
                headers: req.headers.set('Authorization', data.getIdToken().getJwtToken())
              });
              return next.handle(authReq);
            } else {
              return next.handle(req);
            }
          }),
          catchError(() => next.handle(req))
        );
    }
  }
}
