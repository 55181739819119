import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {InfluencerProfileInterface} from '../interfaces/influencer-profile.interface';

@Injectable({
  providedIn: 'root'
})
export class AdminInfluencersService {

  private readonly baseApiUrl = `${environment.baseApiUrl}`;
  private subject = new Subject<{ influencerId: string }>();

  constructor(private http: HttpClient) {
  }

  public getAllInfluencers(): Observable<InfluencerProfileInterface[]> {
    const apiPath = `influencer`;

    return this.http.get<InfluencerProfileInterface[]>(`${this.baseApiUrl}${apiPath}`)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public sendNewInfluencerCall(influencerId: string): void {
    this.subject.next({influencerId});
  }

  public getNewInfluencerCall(): Observable<any> {
    return this.subject.asObservable();
  }
}
