import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ITagItem} from '../interfaces/tag-item.interface';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  private readonly baseApiUrl = `${environment.baseApiUrl}tags`;

  constructor(private http: HttpClient) {
  }

  public searchTagsByKey(key: string, brand?: string): Observable<ITagItem[]> {
    const apiPath = 'search';
    const options = key ? {params: new HttpParams().set('key', key).set('brand', brand)} : {};

    return this.http.get<ITagItem[]>(`${this.baseApiUrl}/${apiPath}`, options)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public getGlobalTags(offset: number = 0, limit: number = 5, brand?: string): Observable<ITagItem[]> {
    const params = new HttpParams()
      .set('offset', offset.toString())
      .set('limit', limit.toString())
      .set('brand', brand);

    return this.http.get<ITagItem[]>(this.baseApiUrl, {params})
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public addTagsToCollection(tags: string[], brand): Observable<ITagItem[]> {
    return this.http.post<ITagItem[]>(this.baseApiUrl, {brand, tags})
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

}
