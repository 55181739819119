import {createAction, props} from '@ngrx/store';
import {InfluencerProfileInterface} from '../interfaces/influencer-profile.interface';

export const SET_ADD_ITEM_SHOWN = 'SET_ADD_ITEM_SHOWN';
export const SET_ADD_ITEM_HIDDEN = 'SET_ADD_ITEM_HIDDEN';
export const SET_PRELOADER_SHOWN = 'SET_PRELOADER_SHOWN';
export const SET_PRELOADER_HIDDEN = 'SET_PRELOADER_HIDDEN';
export const SET_INFLUENCER = 'SET_INFLUENCER';

export const setAddItemShown = createAction(
  SET_ADD_ITEM_SHOWN
);
export const setAddItemHidden = createAction(
  SET_ADD_ITEM_HIDDEN
);
export const setPreloaderShown = createAction(
  SET_PRELOADER_SHOWN
);
export const setPreloaderHidden = createAction(
  SET_PRELOADER_HIDDEN
);
export const setInfluencer = createAction(
  SET_INFLUENCER,
  props<{ influencer: InfluencerProfileInterface }>()
);
