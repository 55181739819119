import {Resolve, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {RegistrationService} from '../services/registration.service';
import {UserRoleEnum} from '@enums';
import {BrandService} from '../services/brand.service';

@Injectable()
export class LoggedInUserCheckResolver implements Resolve<boolean> {

  constructor(
    private router: Router,
    private registrationService: RegistrationService,
    private brandService: BrandService
  ) {
  }

  resolve(): Promise<any> {
    return this.registrationService.getCurrentUserInfo()
      .then(user => {
        if (user) {
          let path = '/my-looks';
          if (this.brandService.getCurrentBrand().value !== this.brandService.defaultBrand) {
            path = '/my-reviews';
          }
          const route = user.attributes && user.attributes['custom:role'] === UserRoleEnum.Admin ? '/dashboard' : path;
          this.router.navigate([route]).catch(err => console.error(err));
          return true;
        } else {
          return true;
        }
      });
  }
}
