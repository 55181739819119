import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private readonly baseApiUrl = `${environment.baseApiUrl}`;

  constructor(private http: HttpClient) {
  }

  public getPerformanceOverview(influencerId: string): Observable<DashboardPerformanceOverviewInterface> {
    const apiPath = 'affiliate/report/general';

    return this.http.get<DashboardPerformanceOverviewInterface>(`${this.baseApiUrl}${apiPath}/${influencerId}`)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public getMyLooksPerformanceData(influencerId: string): Observable<DashboardMyLooksInterface[]> {
    const apiPath = 'affiliate/report/looks';

    return this.http.get<DashboardMyLooksInterface[]>(`${this.baseApiUrl}${apiPath}/${influencerId}`)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public getBestSellingProducts(influencerId: string): Observable<DashboardBestProductsInterface[]> {
    const apiPath = 'affiliate/report/products';

    return this.http.get<DashboardBestProductsInterface[]>(`${this.baseApiUrl}${apiPath}/${influencerId}`)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }
}
