import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {LookItemInterface} from '../interfaces/look-item.interface';

@Injectable({
  providedIn: 'root'
})
export class NewLookService {

  private readonly baseApiUrl = `${environment.baseApiUrl}`;

  constructor(private http: HttpClient) {
  }

  public upsertNewLook(data: LookItemInterface): Observable<LookItemInterface> {
    const apiPath = 'look';

    return this.http.post<LookItemInterface>(`${this.baseApiUrl}${apiPath}`, data)
      .pipe(catchError(val => throwError(val)));
  }

  public uploadNewVideo(data: any): Observable<any> {
    const apiPath = 'video/upload';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(`${this.baseApiUrl}${apiPath}`, data, httpOptions)
      .pipe(catchError(val => throwError(val)));
  }

  public uploadNewShortVideo(data: any): Observable<any> {
    const apiPath = 'video/short/upload';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(`${this.baseApiUrl}${apiPath}`, data, httpOptions)
      .pipe(catchError(val => throwError(val)));
  }

  public updateVideoData(data: any): Observable<any> {
    const apiPath = 'video/upload';

    return this.http.put(`${this.baseApiUrl}${apiPath}`, data)
      .pipe(catchError(val => throwError(val)));
  }

  public uploadNewImage(data: any): Observable<any> {
    const apiPath = 'images/upload';

    return this.http.post(`${this.baseApiUrl}${apiPath}`, data)
      .pipe(catchError(val => throwError(val)));
  }

  public setLookPrimaryAsset(data: { lookId: string, assetId: string }): Observable<LookItemInterface> {
    const apiPath = 'look/setPrimaryAsset';

    return this.http.post<LookItemInterface>(`${this.baseApiUrl}${apiPath}`, data)
      .pipe(catchError(val => throwError(val)));
  }

  // public uploadCustomProductImage(data: any): Observable<any> {
  //   const apiPath = 'images/customProduct';
  //
  //   return this.http.post(`${this.baseApiUrl}${apiPath}`, data)
  //     .pipe(catchError(val => throwError(val)));
  // }

}
