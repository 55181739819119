import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {LookItemInterface} from '../interfaces/look-item.interface';

@Injectable({
  providedIn: 'root'
})
export class MyLooksService {

  private readonly baseApiUrl = `${environment.baseApiUrl}`;

  constructor(private http: HttpClient) {
  }

  public getLooksByInfluencer(influencerId: string): Observable<LookItemInterface[]|any> {
    const apiPath = `looks/${influencerId}`;

    return this.http.get(`${this.baseApiUrl}${apiPath}`)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public getLooksCountByInfluencer(influencerId: string, statusIds: number[], type): Observable<{ count: number }> {
    const apiPath = `looks/countByStatus`;

    const options = {
      params: new HttpParams().set('influencerId', influencerId).set('statusIds', statusIds.join(',')).set('type', type)
    };

    return this.http.get<{ count: number }>(`${this.baseApiUrl}${apiPath}`, options)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public duplicateLook(influencerId: string, lookId: string): Observable<any> {
    const apiPath = 'look/duplicate';

    return this.http.post(`${this.baseApiUrl}${apiPath}`, {influencerId, lookId})
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public updateLookStatus(lookId: string, statusId: number): Observable<any> {
    const apiPath = 'look/status';

    return this.http.post(`${this.baseApiUrl}${apiPath}`, {lookId, statusId})
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }
}
