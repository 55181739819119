import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'limit'
})

export class LimitPipe implements PipeTransform {
  transform(items: any[], numberOfShowed: number = 5): any[] {
    if (!items || (Object.entries(items).length === 0 && items.constructor === Object)) {
      return [];
    }
    return items.filter((value, index) => index < numberOfShowed);
  }
}
