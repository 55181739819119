import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import * as filestack from 'filestack-js';
import {NzMessageService, NzModalService, NzNotificationService} from 'ng-zorro-antd';
import {PickerFileMetadata} from 'filestack-js/build/main/lib/picker';
import {AppConstants} from '../../../../app-constants';
import {NewLookService} from '../../../../services/new-look.service';
import {environment} from '../../../../../environments/environment';
import {Step3ExamplesComponent} from './step3-examples/step3-examples.component';
import {LookItemInterface} from '../../../../interfaces/look-item.interface';
import {LookImageInterface} from '../../../../interfaces/look-image.interface';
import {ISelectedImages} from '../../../../interfaces/selected-images.interface';
import {BrandEnum} from '@enums';
import {BrandService} from '../../../../services/brand.service';
import {distinctUntilChanged} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Subject, Subscription} from 'rxjs';
import {
  BrandRulesManager,
  REQUIRED_LOOK_IMAGES, REQUIRED_REVIEW_IMAGES,
  RULE_ALLOW_STORE_IMAGES_TO_REVIEW_PATH,
  RULE_LOOK_AFTER_IMAGE, RULE_LOOK_AFTER_SHOOT, RULE_LOOK_APPLICATION_SHOOT,
  RULE_LOOK_BEFORE_IMAGE,
  RULE_LOOK_IMAGE_PRODUCT_DESC,
  RULE_LOOK_LIFESTYLE_IMAGE, RULE_LOOK_LOOK_HEADER,
  RULE_LOOK_PRODUCT_IMAGE, RULE_LOOK_REVIEW_HEADER, RULE_LOOK_SHELFIE_IMAGE, RULE_REVIEW_IMAGE_PRODUCT_DESC
} from '../../../../helpers/BrandRulesManager';

@Component({
  selector: 'app-new-look-step3',
  templateUrl: './new-look-step3.component.html',
  styleUrls: ['./new-look-step3.component.less'],
  providers: [DatePipe]
})
export class NewLookStep3Component implements OnInit, OnChanges, OnDestroy {

  public readonly rules = {
    beforeImage: RULE_LOOK_BEFORE_IMAGE,
    afterImage: RULE_LOOK_AFTER_IMAGE,
    productImage: RULE_LOOK_PRODUCT_IMAGE,
    lifestyleImage: RULE_LOOK_LIFESTYLE_IMAGE,
    lookDesc: RULE_LOOK_IMAGE_PRODUCT_DESC,
    reviewDesc: RULE_REVIEW_IMAGE_PRODUCT_DESC,
    applicationShoot: RULE_LOOK_APPLICATION_SHOOT,
    afterShoot: RULE_LOOK_AFTER_SHOOT,
    shelfieImage: RULE_LOOK_SHELFIE_IMAGE,
    lookHeader: RULE_LOOK_LOOK_HEADER,
    reviewHeader: RULE_LOOK_REVIEW_HEADER,
  };

  @Input() private lookId: string;
  @Input() private influencerId: string;
  @Input() public set lookData(data: LookItemInterface) {
    this._lookData = data;
    this.lookData$.next(data);
  }
  @Input() public isAdmin: boolean;
  @Input() public submitLoading: boolean;
  @Output() public onSubmitForm = new EventEmitter<any>();

  public get lookData(): LookItemInterface  {
    return this._lookData;
  }
  public loadingSubmit: boolean;
  public selectedImagesObj: ISelectedImages = {};
  public newLookStep3Form: FormGroup;
  public brand: string = BrandEnum['Il Makiage'];
  public brand$: Subject<string> = new Subject<string>();
  public isReview: boolean = false;
  public lookData$: BehaviorSubject<LookItemInterface> = new BehaviorSubject<LookItemInterface>(null);

  private subscription = new Subscription();
  private _lookData: LookItemInterface = null;
  private filestackTransformations = AppConstants.filestackTransformations;
  private filestackOptions = AppConstants.filestackOptions;
  private filestackClient = filestack.init(environment.filestack_LookImageUploader_ClientKey);

  constructor(
    private fb: FormBuilder,
    private newLookService: NewLookService,
    private message: NzMessageService,
    private datePipe: DatePipe,
    private notification: NzNotificationService,
    private modalService: NzModalService,
    private brandService: BrandService
  ) {
  }

  ngOnInit() {
    this.subscribeOnLoadForm();
    this.subscribeOnBrand();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private subscribeOnLoadForm() {
    this.subscription.add(
      combineLatest([this.brand$, this.lookData$]).pipe(
      ).subscribe(e => {
        this.initFrom(
          e[1],
          BrandRulesManager.isActionAvailable(this.brand, REQUIRED_LOOK_IMAGES),
          BrandRulesManager.isActionAvailable(this.brand, REQUIRED_REVIEW_IMAGES)
        );

        if (!!e[1]) {
          this.selectedImagesObj['beforeImage'] = e[1].beforeImage;
          this.selectedImagesObj['afterImage'] = e[1].afterImage;
          this.selectedImagesObj['bodyImage'] = e[1].bodyImage;
          this.selectedImagesObj['productsImage'] = e[1].productsImage;
          this.selectedImagesObj['applicationShoot'] = e[1].applicationShoot;
          this.selectedImagesObj['afterShoot'] = e[1].afterShoot;
          this.selectedImagesObj['shelfieImage'] = e[1].shelfieImage;
        }
      })
    );
  }

  private subscribeOnBrand() {
    this.subscription.add(
      this.brandService.getCurrentBrand()
        .pipe(
          distinctUntilChanged()
        )
        .subscribe(brand => {
          this.brand = brand === '' ? this.brandService.defaultBrand : brand;
          this.isReview = this.brand !== this.brandService.defaultBrand;
          this.brand$.next(this.brand);
        })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  private initFrom(lookData?: LookItemInterface, requiredLookImages: boolean = true, requiredReviewImages: boolean = false): void {
    this.newLookStep3Form = this.fb.group({
      beforeImage: [lookData && lookData.beforeImage || '', (requiredLookImages ? [Validators.required] : [])],
      afterImage: [lookData && lookData.afterImage || '', (requiredLookImages ? [Validators.required] : [])],
      bodyImage: [lookData && lookData.bodyImage || '', []],
      productsImage: [lookData && lookData.productsImage || '', []],
      applicationShoot: [lookData && lookData.applicationShoot || '', (requiredReviewImages ? [Validators.required] : [])],
      afterShoot: [lookData && lookData.afterShoot || '', (requiredReviewImages ? [Validators.required] : [])],
      shelfieImage: [lookData && lookData.shelfieImage || '', []]
    });
  }

  public openFilestackPicker = (section: string) => {
    const onFileUploadFinished = (file: PickerFileMetadata) => {
      const payload = {
        filename: file.filename,
        rawUrl: `${AppConstants.baseUrlPrefixForS3}/${file.container}/${file.key}`,
        fileSize: file.size,
        influencerId: this.influencerId,
        type: section,
        lookId: this.lookId,
        cdnUrl: file.url,
        brand: this.brand,
        displayUrl: `${AppConstants.baseUrlPrefixForFilestack}/${this.filestackTransformations}/${file.handle}`,
        metadata: {
          mimetype: file.mimetype,
          status: file.status,
          source: file.source,
          originalFile: file.originalFile,
          originalPath: file.originalPath,
          uploadId: file.uploadId,
          handle: file.handle
        }
      };

      this.newLookService.uploadNewImage(payload)
        .subscribe((data: LookImageInterface) => {
          this.selectedImagesObj[section] = data;
          this.newLookStep3Form.get(section).setValue(data.displayUrl);
          if (this.lookData) {
            this.lookData[section] = data;
          }
        });
    };

    const onFileSelected = (file) => {
      const ext = file.filename.split('.').pop();
      const filename = `${this.influencerId}-${this.lookId}-${section}-${this.datePipe.transform(new Date(), 'yyyy-MM-dd-hh-mm')}.${ext}`;
      return {...file, name: filename};
    };

    const config = {...{}, ...this.filestackOptions};
    if (BrandRulesManager.isActionAvailable(this.brand, RULE_ALLOW_STORE_IMAGES_TO_REVIEW_PATH)) {
      config['storeTo'].path = '/reviews/';
    }

    const picker = this.filestackClient.picker({
      ...config,
      onFileUploadFinished,
      onFileSelected,
      onFileUploadFailed: (file: PickerFileMetadata, error: Error) => {
        picker.close()
          .then(() => {
            this.message.error('We we’re unable to upload your image. Please try again.');
          });
      }
    });

    picker.open()
      .catch(err => console.log(err));
  }

  public removeImage(section: string): void {
    this.selectedImagesObj[section] = null;
    this.newLookStep3Form.get(section).reset();
    if (this.lookData) {
      this.lookData[section] = null;
    }
  }

  public setLookPrimaryAsset(assetId: string): void {
    this.newLookService.setLookPrimaryAsset({lookId: this.lookId, assetId})
      .subscribe(() => this.notification.create('success',
        'Success',
        'This image will now be the primary asset')
      );
  }

  public submitForm(): void {
    for (const i in this.newLookStep3Form.controls) {
      this.newLookStep3Form.controls[i].markAsDirty();
      this.newLookStep3Form.controls[i].updateValueAndValidity();
    }

    if (this.newLookStep3Form.valid) {
      this.loadingSubmit = true;
      const idsList = {};

      for (const section in this.selectedImagesObj) {
        if (this.selectedImagesObj.hasOwnProperty(section)) {
          if (this.selectedImagesObj[section]) {
            idsList[section] = this.selectedImagesObj[section]._id;
          } else {
            idsList[section] = null;
          }
        }
      }
      this.loadingSubmit = false;
      this.onSubmitForm.emit({step: 3, ...idsList});
    } else {
      window.scroll(0, 0);
      this.message.error('Something is missing, check below for more info');
    }
  }

  public openExamples(): void {
    this.modalService.create({
      nzContent: Step3ExamplesComponent,
      nzFooter: null,
      nzWidth: '645px'
    });
  }
}
