import {AppState} from './state';
import {createFeatureSelector, createSelector} from '@ngrx/store';

const featureState = createFeatureSelector<AppState>('app');

export const selectInfluencer = createSelector(featureState, (state) => state.influencer);

export const selectInfluencerIsMultiBrand = createSelector(featureState, (state) => state.influencer && state.influencer.brand.length > 1);

export const selectInfluencerBrands = createSelector(featureState, (state) => state.influencer && typeof state.influencer['brand'] !== 'undefined' ? state.influencer.brand : null);
