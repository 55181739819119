// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'development',
  baseApiUrl: 'https://api.develop.kenzza.com/',
  storeBaseApiUrl: 'https://store.api.dev.ilmakiage.com/',
  fileStack_VideoUploader_ClientKey: 'AJlbYGZ66RzWYy8cT0LSMz',
  filestack_LookImageUploader_ClientKey: 'AFlGWm2uVRi8oo1MSIlDAz',
  filestack_ProfileImageUploader_ClientKey: 'AnMzn5qKCQIWdk3AvOsWlz',
  staticFilesUrl: 'https://files.ilmakiage.com',
  signUpStorageKey: 'signUpFirstStepCompletedEmail-dev',
  storeWebAppUrl: 'http://develop.ilmakiage.com',
  sentryDsnUrl: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
