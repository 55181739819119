import {Component, OnDestroy, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import * as filestack from 'filestack-js';
import {PickerFileMetadata} from 'filestack-js/build/main/lib/picker';
import {distinctUntilChanged, filter, map, takeUntil, tap} from 'rxjs/operators';
import {NzMessageService} from 'ng-zorro-antd';
import {AppConstants} from '../../app-constants';
import {MyProfileService} from '../../services/my-profile.service';
import {LookImageInterface} from '../../interfaces/look-image.interface';
import {InfluencerProfileInterface} from '../../interfaces/influencer-profile.interface';
import {IUserAuthResolveInterface} from '../../interfaces/user-auth-resolve.interface';
import {environment} from '../../../environments/environment';
import {AdminInfluencersService} from '../../services/admin-influencers.service';
import {Subject} from 'rxjs';
import {CheckboxFormItemInterface} from '../../interfaces/checkbox-form-item.interface';
import {BrandEnum, UserRoleEnum} from '@enums';
import {BrandService} from '../../services/brand.service';
import {ProfileStylingInterface} from '../../interfaces/profile-styling.interface';
import {
  BrandRulesManager,
  RULE_ALLOW_EDIT_REVIEWS,
  RULE_ALLOW_STORE_IMAGES_TO_REVIEW_PATH,
  RULE_SHOR_HAIR_CONCERN
} from '../../helpers/BrandRulesManager';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../state/state';
import {setAddItemHidden, setAddItemShown} from '../../state/actions';
import {selectInfluencerBrands} from '../../state/selectors';

declare let require: any;
const skinConcernItems = require('../../stubs/skin-concern-items.json');
const hairConcernItems = require('../../stubs/hair-concern-items.json');
const skinTypeItems = require('../../stubs/skin-type-items.json');
const hairTypeItems = require('../../stubs/hair-type-items.json');

function checkSkinTypes(sti: any): ValidatorFn {
  return ($event: FormArray): ValidationErrors | null => {
    if (!$event || !$event.controls) {
      return null;
    }
    const selectedIds = $event.controls
      .map((e, i) => !!e.value ? i : undefined)
      .filter(e => typeof e !== 'undefined');

    let allowToAddOneMore = true;
    selectedIds.forEach((e: number) => {
      if (allowToAddOneMore) {
        allowToAddOneMore = sti[e].applyOneMoreItem;
      }
    });

    $event.controls.forEach((e, i) => {
      sti[i].disable = selectedIds.indexOf(i) === -1 && !allowToAddOneMore;
    });

    return null;
  };
}

export const checkedOneOfListOrMore = (isHairSkinValidationRequired: boolean = true): ValidatorFn => {
  return (c: FormArray): ValidationErrors | null => {
    if (!isHairSkinValidationRequired) {
      return null;
    }
    return c.controls.filter(e => e.value === true).length > 0 ? null : {MinLengthArray: true};
  };
};

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.less'],
  providers: [DatePipe]
})
export class MyProfileComponent implements OnInit, OnDestroy {
  public readonly rules = {
    showHairConcern: RULE_SHOR_HAIR_CONCERN,
    showHairType: RULE_SHOR_HAIR_CONCERN,
  };
  private filestackClient = filestack.init(environment.filestack_ProfileImageUploader_ClientKey);
  private filestackOptions = AppConstants.filestackOptions;
  private influencerId: string;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public readonly bioCharsLimit = 100;
  public loadingData = true;
  public loadingSubmit: boolean;
  public filestackTransformations = AppConstants.filestackTransformations;
  public myProfileForm: FormGroup;
  public isAdmin: boolean;
  public isContentEditor: boolean;
  public isHairSkinValidationRequired = true;
  public isProfessional: boolean;
  public influencerData: InfluencerProfileInterface;
  public profileImage: LookImageInterface;
  public readonly skinToneList = [
    {name: 'Light', value: 'light'},
    {name: 'Medium', value: 'medium'},
    {name: 'Tan', value: 'tan'},
    {name: 'Deep', value: 'deep'}];
  public skinConcernList: CheckboxFormItemInterface[] = skinConcernItems;
  public hairConcernList: CheckboxFormItemInterface[] = hairConcernItems;
  public skinTypeList: CheckboxFormItemInterface[] = skinTypeItems;
  public hairTypeList: CheckboxFormItemInterface[] = hairTypeItems;
  public readonly brandEnum = BrandEnum;
  public currentBrand: string = BrandEnum['Il Makiage'];
  public styling: ProfileStylingInterface = {
    isCustomBrand: false
  };
  public isHairCareMode = false;

  constructor(
    private fb: FormBuilder,
    private myProfileService: MyProfileService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    private message: NzMessageService,
    private adminInfluencersService: AdminInfluencersService,
    private brandService: BrandService,
    private store: Store<{ app: AppState }>
  ) {
  }

  ngOnInit() {
    this.store.dispatch(setAddItemHidden());

    const userData: IUserAuthResolveInterface = this.route.snapshot.parent.data.userData;
    this.influencerId = userData.influencerId;
    this.isAdmin = userData.isAdmin;
    this.isContentEditor = (userData && userData.role === 'content_editor');
    this.isProfessional = (userData && userData.role === UserRoleEnum.Professional);
    this.checkIsHairSkinValidationRequired();

    this.adminInfluencersService.getNewInfluencerCall()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.influencerId = data.influencerId;
        this.showWarningIfNotProfileCompleted();
        this.getInfluencerProfileData();
      });

    this.showWarningIfNotProfileCompleted();
    this.getInfluencerProfileData();
    this.subscribeOnInfluencerMultiBrand();
    this.subscribeOnBrandChanged();
  }

  ngOnDestroy() {
    this.store.dispatch(setAddItemShown());
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private subscribeOnInfluencerMultiBrand() {
    this
      .store
      .pipe(
        select(selectInfluencerBrands),
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        filter(e => Array.isArray(e)),
        map(e => {
          return e.reduce((prev, curr) => {
            prev[curr] = 1;
            return prev;
          }, {});
        })
      )
      .subscribe(e => {
        delete e[this.brandService.defaultBrand];
        this.isHairCareMode = Object.keys(e).length > 0;
      });
  }

  private getExtraValidateControls() {
    return {
      hairConcern: this.isHairCareMode,
      hairType: this.isHairCareMode
    };
  }

  private subscribeOnBrandChanged() {
    this.brandService
      .getCurrentBrand()
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        tap(data => this.currentBrand = data === '' ? this.brandService.defaultBrand : data)
      )
      .subscribe(data => this.calculateStyling());
  }

  private calculateStyling() {
    this.styling.isCustomBrand = this.currentBrand !== this.brandService.defaultBrand;
  }

  private showWarningIfNotProfileCompleted() {
    this.route.paramMap
      .pipe(
        takeUntil(this.destroy$),
        map(() => window.history.state)
      )
      .subscribe(state => {
        if (state && state.isProfileCompleted === false) {
          this.message.error('In order to upload you first look you must fill-in all the details below.', {nzDuration: 5000});
        }
      });
  }

  private getInfluencerProfileData() {
    this.myProfileService.getInfluencerProfileData(this.influencerId)
      .pipe(
        filter(e => !!e),
        takeUntil(this.destroy$)
      )
      .subscribe((data: InfluencerProfileInterface) => {
        this.influencerData = data;
        this.initForm(data, this.getExtraValidateControls());
        this.profileImage = data && data.profileImage;
        this.loadingData = false;
      });
  }

  private transformStringToArray(stringOfArray: string | string[]) {
    return Array.isArray(stringOfArray) ? stringOfArray : [stringOfArray];
  }

  private checkIsHairSkinValidationRequired() {
    this.isHairSkinValidationRequired = !this.isProfessional && !this.isAdmin;
  }

  private initForm(data: any, validateControls: any = {}) {
    const profileImageId = data && data.profileImage && data.profileImage._id;
    this.checkIsHairSkinValidationRequired();

    this.myProfileForm = this.fb.group({
      firstName: [data && data.firstName || '', [Validators.required, Validators.minLength(2)]],
      lastName: [data && data.lastName || '', [Validators.required, Validators.minLength(2)]],
      profileImage: ['', profileImageId ? [] : [Validators.required]],
      bio: [data && data.bio || '', [Validators.required, Validators.maxLength(this.bioCharsLimit)]],
      skinTone: [data && data.skinTone || null, this.isHairSkinValidationRequired ? [Validators.required] : []],
      skinType: new FormArray(this.skinTypeList
        .map(e => new FormControl(this.transformStringToArray(data ? data.skinType : [])
          .indexOf(e.value) !== -1)), [
        checkSkinTypes(this.skinTypeList),
        checkedOneOfListOrMore(this.isHairSkinValidationRequired)
      ]),
      hairType: new FormArray(this.hairTypeList
        .map(e => new FormControl(this.transformStringToArray(data ? data.hairType : [])
          .indexOf(e.value) !== -1)), validateControls.hairType
        ? [checkedOneOfListOrMore(this.isHairSkinValidationRequired)] : []),
      dob: [data && data.dob || '', [Validators.required]],
      skinConcern: new FormArray(this.skinConcernList
        .map(e => new FormControl(this.transformStringToArray(data ? data.skinConcern : [])
          .indexOf(e.value) !== -1)), [checkedOneOfListOrMore(this.isHairSkinValidationRequired)]),
      hairConcern: new FormArray(this.hairConcernList
          .map(e => new FormControl(this.transformStringToArray(data ? data.hairConcern : [])
            .indexOf(e.value) !== -1)),
        validateControls.hairConcern ? [checkedOneOfListOrMore(this.isHairSkinValidationRequired)] : []),
      socialAccounts: this.fb.group({
        instagram: this.fb.group({
          username: [data && data.socialAccounts && data.socialAccounts.instagram && data.socialAccounts.instagram.username || '', [
            Validators.required, Validators.pattern(/^[a-zA-Z0-9._]+$/)]],
          followers: [data && data.socialAccounts && data.socialAccounts.instagram && data.socialAccounts.instagram.followers || '', []]
        }),
        youtube: this.fb.group({
          username: [data && data.socialAccounts && data.socialAccounts.youtube && data.socialAccounts.youtube.username || '', []],
          followers: [data && data.socialAccounts && data.socialAccounts.youtube && data.socialAccounts.youtube.followers || '', []]
        }),
        tiktok: this.fb.group({
          username: [data && data.socialAccounts && data.socialAccounts.tiktok && data.socialAccounts.tiktok.username || '', []],
          followers: [data && data.socialAccounts && data.socialAccounts.tiktok && data.socialAccounts.tiktok.followers || '', []]
        })
      })
    });
  }

  public openFilestackPicker(section: string): void {
    const onFileUploadFinished = (file: PickerFileMetadata) => {
      const payload = {
        filename: file.filename,
        rawUrl: `${AppConstants.baseUrlPrefixForS3}/${file.container}/${encodeURIComponent(file.key)}`,
        fileSize: file.size,
        influencerId: this.influencerId,
        type: section,
        brand: this.currentBrand,
        cdnUrl: file.url,
        displayUrl: `${AppConstants.baseUrlPrefixForFilestack}/${this.filestackTransformations}/${file.handle}`,
        metadata: {
          mimetype: file.mimetype,
          status: file.status,
          source: file.source,
          originalFile: file.originalFile,
          originalPath: file.originalPath,
          uploadId: file.uploadId,
          handle: file.handle
        }
      };
      this.myProfileService.uploadProfileImage(payload)
        .subscribe((data: LookImageInterface) => {
          this.profileImage = data;
          this.myProfileForm.get('profileImage').setValue(data.displayUrl);
        });
    };

    const onFileSelected = (file) => {
      const ext = file.filename.split('.').pop();
      const filename = `profile-${this.influencerId}-${this.datePipe.transform(new Date(), 'yyyy-MM-dd-hh-mm')}.${ext}`;
      return {...file, name: filename};
    };

    const config = {...{}, ...this.filestackOptions};
    if (BrandRulesManager.isActionAvailable(this.currentBrand, RULE_ALLOW_STORE_IMAGES_TO_REVIEW_PATH)) {
      config['storeTo'].path = '/reviews/';
    }

    const picker = this.filestackClient.picker({
      ...config,
      onFileUploadFinished,
      onFileSelected,
      onFileUploadFailed: (file: PickerFileMetadata, error: Error) => {
        picker.close()
          .then(() => {
            this.message.error('We we’re unable to upload your image. Please try again.');
          });
      }
    });
    picker.open()
      .catch(err => console.log(err));
  }

  public submitForm(): void {
    if (this.myProfileForm.invalid) {
      for (const i in this.myProfileForm.controls) {
        this.myProfileForm.controls[i].markAsDirty();
        this.myProfileForm.controls[i].updateValueAndValidity();
      }
      return;
    }
    this.loadingSubmit = true;
    const skinType = this.getSelectedItemsValue(this.myProfileForm.value.skinType, this.skinTypeList);
    const skinConcern = this.getSelectedItemsValue(this.myProfileForm.value.skinConcern, this.skinConcernList);
    const hairConcern = this.getSelectedItemsValue(this.myProfileForm.value.hairConcern, this.hairConcernList);
    const hairType = this.getSelectedItemsValue(this.myProfileForm.value.hairType, this.hairTypeList);

    const payload = {
      ...this.myProfileForm.value,
      profileImage: this.profileImage._id,
      isProfileCompleted: true,
      skinType: skinType,
      skinConcern: skinConcern,
      hairConcern: hairConcern,
      hairType: hairType,
    };

    this.myProfileService.updateInfluencerProfileData(payload, this.influencerId)
      .subscribe(() => {
        this.loadingSubmit = false;
        const path = BrandRulesManager.isActionAvailable(this.currentBrand, RULE_ALLOW_EDIT_REVIEWS) ? '/my-reviews' : '/my-looks';

        this.router.navigate([path])
          .catch(err => console.error(err));
      });
  }

  private getSelectedItemsValue(selectedItems: Array<string>, collection: CheckboxFormItemInterface[]) {
    const indexes = selectedItems.reduce((acc, item, index) => {
      if (item) {
        acc.push(index);
      }
      return acc;
    }, []);

    const result = [];
    indexes.forEach((e: number) => {
      result.push(collection[e].value);
    });

    return result;
  }
}
