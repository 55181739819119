import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FormGroup} from '@angular/forms';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {ProductsOrderSetInterface} from '../interfaces/products-order-set.interface';
import {BrandEnum} from '@enums';

@Injectable({
  providedIn: 'root'
})
export class ProductsSelectService {
  private skusList: string[] = [];
  private readonly baseApiUrl = `${environment.baseApiUrl}`;

  constructor(private http: HttpClient) {
  }

  public getProductsList(featureName: string, countryName: string = 'US', brand: string = BrandEnum['Il Makiage']): Observable<ProductsOrderSetInterface[]> {
    const apiPath = 'categories';

    if (countryName.toUpperCase() === 'CANADA') {
      countryName = 'US';
    }

    const options = {
      params: new HttpParams().set('featureName', featureName).set('country-code', countryName.toLowerCase()).set('brand', brand)
    };

    return this.http.get<ProductsOrderSetInterface[]>(`${this.baseApiUrl}products/${apiPath}`, options)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public submitSelectedProducts(form: FormGroup): any {
    const apiPath = 'productsOrder/export';
    const payload = Object.assign({}, form.value, {products: this.skusList});
    return this.http.post(`${this.baseApiUrl}${apiPath}`, payload)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public submitNewCustomProduct(payload) {
    const apiPath = 'custom';
    return this.http.post(`${this.baseApiUrl}products/${apiPath}`, payload)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public setSKUsSelection(skusList: string[]): void {
    this.skusList = skusList;
  }

  public getSKUsSelection(): string[] {
    return this.skusList;
  }

}
