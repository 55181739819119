import {createReducer, on} from '@ngrx/store';
import {setAddItemShown, setAddItemHidden, setPreloaderHidden, setPreloaderShown, setInfluencer} from './actions';
import {INITIAL_APP_STATE} from './state';
import {InfluencerProfileInterface} from '../interfaces/influencer-profile.interface';

export const initialState = 0;

// @ts-ignore
const _appReducer = createReducer(
  INITIAL_APP_STATE,
  on(
    setAddItemShown,
    (state, {}) => ({ ...state, isAddItemShown: true })
  ),
  on(
    setAddItemHidden,
    (state, {}) => ({ ...state, isAddItemShown: false })
  ),
  on(
    setPreloaderShown,
    (state, {}) => ({ ...state, isPreloaderShown: true })
  ),
  on(
    setPreloaderHidden,
    (state, {}) => ({ ...state, isPreloaderShown: false })
  ),
  on(
    setInfluencer,
    (state, { influencer }) => ({ ...state, influencer })
  )
);

export function appReducer(state, action) {
  return _appReducer(state, action);
}
