import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {InfluencerInviteInterface} from '../interfaces/influencer-invite.interface';

@Injectable({
  providedIn: 'root'
})
export class InviteInfluencersService {

  private readonly baseApiUrl = `${environment.baseApiUrl}`;

  constructor(private http: HttpClient) {
  }

  public getAllInvites(): Observable<InfluencerInviteInterface[]> {
    const apiPath = `invites`;

    return this.http.get<InfluencerInviteInterface[]>(`${this.baseApiUrl}${apiPath}`)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public generateToken(payload: any): Observable<InfluencerInviteInterface> {
    const apiPath = 'invites/generateToken';

    return this.http.post<InfluencerInviteInterface>(`${this.baseApiUrl}${apiPath}`, payload)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

  public updateInvite(tokenId: string, payload: any): Observable<InfluencerInviteInterface> {
    const apiPath = `invites/${tokenId}`;

    return this.http.post<InfluencerInviteInterface>(`${this.baseApiUrl}${apiPath}`, payload)
      .pipe(catchError(val => {
        return throwError(val);
      }));
  }

}
